import React, { useState, useEffect, Suspense, lazy } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Install from "./pages/Install";
import zoomSdk from "@zoom/appssdk";
import { bindZoomData, bindZoomIsInstalled } from "./store/donationSlice";
import LiveStream from "./pages/LiveStream";
import axios from "axios";
import CryptoJS from "crypto-js";
import Protected from "./pages/Protected";
import Loading from "./pages/Loading";
import PublishForm from "./pages/PublishForm";
import  { SALT_KEY,AUTH_API,ZOOM_USER_API,ZOOM_SDB_API }  from "./utility/constants";
import { Authorization } from "./pages/Authorization";
import Success from "./pages/Success";
import Auth from "./pages/Auth";
//import Auth from "./pages/Auth";

const HostPage = lazy(() => import("./pages/HostLanding"));
const UserPage = lazy(() => import("./pages/UserDonationHome"));

const capabilities = [
  "shareApp",
  "showNotification",
  "getUserContext",
  "getMeetingContext",
  "getMeetingParticipants",
  "getAppContext",
  "getRunningContext",
  "onMyUserContextChange",
  "authorize",
  "onAuthorized",
  "onMeeting",
  "promptAuthorize",
  "getSupportedJsApis",
  "showNotification",
  "setVirtualBackground",
  "removeVirtualBackground",
  "getMeetingUUID",
  "drawParticipant",
  "drawWebview",
  "onMyMediaChange",
  "runRenderingContext",
  "closeRenderingContext",
  "postMessage",
  "sendAppInvitationToAllParticipants",
  "onMessage",
];

function App() {
  const [userinfo, setUserInfo] = useState("");
  const [useraccountinfo, setUserAccountInfo] = useState("");
  const [runningContext, setRunningContext] = useState();
  const [supportedApis, setSupportedApis] = useState([]);
  const [meetingUUID, setMeetingUUID] = useState({});
  const [userContext, setUserContext] = useState({});
  const [zoomSdkStarted, setZoomSdkStarted] = useState(false);
  const [dimensions, setDimensions] = useState();
  const [meetinginfo, setMeetingInfo] = useState();
  const [appoutofcontext, setAppOutOfContext] = useState(false);
  const [isExistingForm, setIsExistingForm] = useState(false);
  const [checkFormExists, setCheckFormExists] = useState("");
  const [userContextStatus, setUserContextStatus] = useState("");
  const [zoomnotsupported, setZoomNotSupported] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  /**ZoomSdk Config */
  const apiSupported = (apiOrEvent) => supportedApis.indexOf(apiOrEvent) > -1;

  const initZoomSdk = (retry) => {
    zoomSdk.config({ version: "0.16", capabilities }).then(async (resp) => {
      if (resp.media) setDimensions(resp.media.renderTarget);
      setUserContextStatus(resp.auth.status);
      setRunningContext(resp.runningContext);
      setZoomSdkStarted(true);
      const userinfo = await zoomSdk.getUserContext();
      setUserInfo(userinfo);
     
      if (retry) retry();
    });
  };

 

  useEffect(() => {
    const registeredApis = [];

    const contexts = [
      "inMeeting",
      "inWebinar",
      "inCollaborate",
      "inMainClient",
      "inCamera",
    ];
    if (contexts.includes(runningContext)) {
      if (apiSupported("getMeetingUUID")) getMeetingUUID();
      if (apiSupported("getUserContext")) getUserContext();
      if (apiSupported("getMeetingContext")) getMeetingContextAPI();
    }

    return () => registeredApis.forEach((unregister) => unregister());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [runningContext, supportedApis]);

  useEffect(initZoomSdk, []);

  useEffect(() => {
    if (zoomSdkStarted) {
      console.log("zoomSdk version: ", zoomSdk._version, meetingUUID);
      zoomSdk.getSupportedJsApis().then(({ supportedApis }) => {
        setSupportedApis(supportedApis);
      }).catch(err => {
        setZoomNotSupported(true);
      });
    }else{
      setZoomNotSupported(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zoomSdkStarted]);

  const getMeetingContextAPI = () =>{
    zoomSdk.getMeetingContext().then((meetingData)=>{
      setMeetingInfo(meetingData);
    }).catch(err => console.log("e==",err));
    
  } 

  const getMeetingUUID = () =>
    zoomSdk
      .getMeetingUUID()
      .then((meetingUUID) => {
        
            const muid = {
              meetingUUID: meetingUUID.meetingUUID.replace(/[^A-Za-z0-9]/g,"")
            }; 

           setMeetingUUID(muid)
         
         })
      .catch((err) => {
        setAppOutOfContext(true);
      });

  const getUserContext = () =>
    zoomSdk
      .getUserContext()
      .then((userContext) => setUserContext(userContext))
      .catch((err) => {
        setAppOutOfContext(true);
      });

      useEffect(()=>{
        
      if(meetingUUID && userinfo && !useraccountinfo){
        let postData = {
          tableName: meetingUUID.meetingUUID+"-participants",
          userId: userinfo.participantId
        }
       
        let postBody = CryptoJS.AES.encrypt(
          JSON.stringify({ body: { postData } }),
          SALT_KEY
        ).toString();
        axios
                .post(
                  `${ZOOM_USER_API}/getuser`,
                  { httpMethod: "POST", path: "/", postBody }
                ).then((res)=>{
                 
                    if(res.data && res.data.statusCode === 200 && res.data.body.Items[0]){
                      const zoomuData = res.data.body.Items[0].Attributes;
                      const display_name = zoomuData[4].Value;
                      let first_name='', last_name=''
                      if(display_name.indexOf(' ') >= 0){
                        first_name=  display_name.split(' ')[0];
                        last_name = display_name.split(' ')[1];
                      }
                      const zoomuserinfo = {
                        email: zoomuData[0].Value,
                        display_name,
                        first_name,
                        last_name,
                        userId: zoomuData[5].Value
                      }
                    
                      setUserAccountInfo(zoomuserinfo);
                   
                      dispatch(bindZoomData(zoomuserinfo));
                    }
                  
                }).catch(err => console.log("eeee===",err))
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[meetingUUID, userinfo])

    
  useEffect(() => {
    const getLastItem = (thePath) =>
    thePath.substring(thePath.lastIndexOf("/") + 1);
    const path = getLastItem(window.location.href).split("?")[0];
    if (path === "auth" && !userinfo) {
      const authCode = new URL(window.location.href).searchParams.get("code");
      // const state = new URL(window.location.href).searchParams.get('state');
      // const verifier = localStorage.getItem("verifier");
      const bodyData = {
        code: authCode,
        //verifier: verifier
      };
      axios
        .post(
          `${AUTH_API}/auth`,
          { httpMethod: "POST", path: "/auth", body: bodyData },
          { headers: { "Content-Type": "application/json" } }
        )
        .then((res) => {
          dispatch(bindZoomIsInstalled(true));
          localStorage.setItem("iszoomappinstalled", true);
          window.location.href = "/";
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isExistingForm && useraccountinfo && meetingUUID) {
      setCheckFormExists("started");
      // const userparams = {
      //   eId: meetinginfo.meetingID,
      //   zoomaccId: useraccountinfo.account_id,
      // };
   
      const userparams = {
        eId: meetingUUID.meetingUUID,
      };
      let postBody = CryptoJS.AES.encrypt(
        JSON.stringify({ body: { userparams } }),
        SALT_KEY
      ).toString();
      axios
        .post(
          `${ZOOM_SDB_API}/getuserform`,
          { httpMethod: "POST", path: "/getuserform", postBody }
        )
        .then((res) => {
          
          if (res.data && res.data.body && res.data.body.Items) {
            const guid = res.data.body.Items[0].Attributes[3].Value;
            if (guid) {
              setIsExistingForm(true);
              setCheckFormExists("finished");
              navigate("/loadform", { state: { showDForm: true } });
            }
          }
          setCheckFormExists("finished");
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [useraccountinfo]);

  let browserName = (function (agent) {
    switch (true) {
      case agent.indexOf("mobile") > -1:
        return "Mobile"; 
      case agent.indexOf("zoomapps") > -1:
        return "ZoomDesktop";
      default:
        return "other";
    }
  })(window.navigator.userAgent.toLowerCase());

 //Handle page reload
  const pageAccessedByReload = (
    (window.performance.navigation && window.performance.navigation.type === 1) ||
      window.performance
        .getEntriesByType('navigation')
        .map((nav) => nav.type)
        .includes('reload')
  );
  useEffect(()=>{
      if(pageAccessedByReload){
             navigate('/')
      }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[pageAccessedByReload])
  

  return (
    <Suspense fallback={<Loading appoutofcontext={appoutofcontext} />}>
      <Routes>
        {/* <Route exact path="/" element={userinfo && userinfo.role === 'host' ? <WelcomePage user={zoomuserinfo} /> : <DonationHome  />} /> */}

        {(browserName === "ZoomDesktop" || browserName === "Mobile") && userinfo  && useraccountinfo && (
          <Route
            exact
            path="/"
            element={
              <Protected
                isHost={userinfo && userinfo.role === "host" ? true : false}
                //isAuthorized = {userinfo && userinfo.status === "authorized" ? true : false}
              >
                {checkFormExists === "finished" && !isExistingForm && userinfo && userinfo.status === "authorized" ? (
                  <>
                 
                    <HostPage
                    meetingData={meetingUUID}
                    userData={useraccountinfo}
                  />
                 
                  </>
                ) : userinfo && userinfo.status !== "authorized" ? (
                  <Install />
                ): (
                  <Loading />
                )}
              </Protected>
            }
          />
        )}

        {(browserName === "ZoomDesktop" || browserName === "Mobile") && userinfo && (
          <Route
            exact
            path="/publishform"
            element={
              <Protected
                isHost={userinfo && userinfo.role === "host" ? true : false}
               
              >
                <PublishForm
                  meetingData={meetingUUID}
                  userData={useraccountinfo}
                />
              </Protected>
            }
          />
        )}

        {(browserName === "ZoomDesktop" || browserName === "Mobile") && userinfo && (

            userinfo && userinfo.status === "authorized" ? (
              <Route
            exact
            path="/loadform"
            element={
              <>
              
              <UserPage
              meetingData={meetingUUID}
              userData={useraccountinfo}
              ishost={userinfo.role === "host" ? true : false}
              userContext={userContext}
              dimensions={dimensions}
              runningContext={runningContext}
              initSdk={initZoomSdk}
              browserName={browserName}
            />
             
             </>
            }
          />
            ):(
              <Route path="/" element={<Install />} />
            )
          
        )}

        {(browserName === "ZoomDesktop" || browserName === "Mobile") && !userinfo && (
          <Route
            path="/"
            element={<Loading appoutofcontext={appoutofcontext} />}
          />
        )}
        {(browserName === "ZoomDesktop" || browserName === "Mobile") && (
          <Route path="/livestream" element={<LiveStream />} />
        )}

        {/* {!userinfo && browserName === "other" && (
          <Route path="/" element={localStorage.getItem('iszoomappinstalled')?<Install />:<Auth />} />
        )} */}
         {!userinfo && browserName === "other" && (
          <Route path="/" element={<Install />} />
        )}
         
          <Route path="/auth" element={<Auth />} />
        
        <Route path="/loading" element={<Loading />} />
        <Route path="/success" element={<Success donateAgain={true} />} />
        <Route path="/authorize" element={
            <Authorization
            handleError={setAppOutOfContext}
            handleUserContextStatus={setUserContextStatus}
            userContextStatus={userContextStatus}
            meetingUUID = {meetingUUID}
            userinfo = {userinfo}
            setUserAccountInfo={setUserAccountInfo}
          />
        } />
        <Route path="*" element={<Loading />} />
      </Routes>
    </Suspense>
  );
}

export default App;
