import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { bindPaymentOrderNo, bindShowHideForm } from "../store/donationSlice";
import "../scss/froala_style.min.css";

const Success = (props) => {

  const formSettings = useSelector((state) => state?.donation?.formSettings || {});
  const paymentOrderNo = useSelector((state) => state?.donation?.paymentOrderNo || "");

  const navigate = useNavigate();
  const dispatch = useDispatch();

 useEffect(()=>{
  window.scrollTo(0, 0);
 },[])

  // useEffect(()=>{
  //   if(formSettings.ThankYouMessageSettings && !formSettings.ThankYouMessageSettings.ShowDefaultThankYouMessage){
  //     const ThankDiv = document.getElementById('thankyou-message');
  //     ThankDiv.innerHTML = decodeURIComponent(formSettings.ThankYouMessageSettings.CustomizedThankYouMessage);
  //   }
   
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // },[formSettings.ThankYouMessageSettings && formSettings.ThankYouMessageSettings.ShowDefaultThankYouMessage])

  const donateAgain = () => {
    dispatch(bindPaymentOrderNo(""))
    dispatch(bindShowHideForm(false));
    navigate("/loadform",{ state: { showDForm: false } });
  };

  return (
    <>
     {/* <img src="newimgs/success-icon1.svg" alt="Click and Pledge" style={{width:'30px', marginLeft:'auto', marginRight:'auto', display:'block', padding:'1rem 0'}}/> */}
     <svg width="30" height="30" style={{width: '30px', height: '30px', margin:'2rem auto 0', display:'block'}} xmlns="https://www.w3.org/2000/svg" viewBox="0 0 16 16">
<path d="M4.7 6.5L3.6 7.6l3.6 3.6 8-8 -1.1-1.1L7.2 9 4.7 6.5zM14.4 8c0 3.5-2.9 6.4-6.4 6.4 -3.5 0-6.4-2.9-6.4-6.4 0-3.5 2.9-6.4 6.4-6.4 0.6 0 1.2 0.1 1.8 0.2L11 0.6C10.1 0.2 9 0 8 0 3.6 0 0 3.6 0 8c0 4.4 3.6 8 8 8 4.4 0 8-3.6 8-8H14.4zM14.4 8" 
fill={formSettings.ThankYouMessageSettings && formSettings.ThankYouMessageSettings.iconColor ? formSettings.ThankYouMessageSettings.iconColor: `#6DA77A`}></path>
</svg>
    {formSettings.ThankYouMessageSettings && formSettings.ThankYouMessageSettings.ShowDefaultThankYouMessage ? (
        <>
         <h1 className="ty_title" style={{textAlign:'center'}}>Thank you for your donation</h1> <br/>
          <p className="ty_text" style={{textAlign:'center'}}>We appreciate your support</p>
          <div style={{fontSize:'0.65rem', textAlign:'center'}}><b>Transaction Result:</b> <span>Transaction processed successfully</span></div>
          <div style={{fontSize:'0.65rem', textAlign:'center', padding:'5px 0'}}><b>Order Number:</b> <span>{paymentOrderNo}</span></div>
          </>
        
    ):(
      <>
      <div id="thankyou-message" dangerouslySetInnerHTML={{ __html: decodeURIComponent(formSettings.ThankYouMessageSettings.CustomizedThankYouMessage) }}>
      
      </div>
      <div style={{fontSize:'0.65rem', textAlign:'center'}}><b>Transaction Result:</b> <span>Transaction processed successfully</span></div>
      <div style={{fontSize:'0.65rem', textAlign:'center', padding:'5px 0'}}><b>Order Number:</b> <span>{paymentOrderNo}</span></div>
      </>
    )}
     
      {props.donateAgain ? (
        <div className="form_input_wrapper d-flex justify-content-between mt_2">
            <button
            className="full_button tabindex_btn next"
            type="button"
            onClick={donateAgain}
            data-index="0"
            >
            {" "}
            Continue!{" "}
            </button>
         </div>
      ): null}
     
    </>
  );
};

export default Success;
