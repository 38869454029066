import React, { useLayoutEffect, useState } from "react";
import { useSelector } from "react-redux";
import zoomSdk from "@zoom/appssdk";
import "../streamcss/Stream.css";
import { replaceAll } from "../utility/util";

function LiveStream(props) {
  const [streamData, setStreamData] = useState("");
  const [totalamount, setTotalAmount] = useState("");

  // const donationStream = useSelector(
  //   (state) => state?.donation?.donationStream || []
  // );
  const formSettings = useSelector(
    (state) => state?.donation?.formSettings || {}
  );

  const targetAmount = formSettings.CampaignDetails && formSettings.CampaignDetails.Target > 0 ? formSettings.CampaignDetails.Target : 1000;
  //const streamDisplayFormat = formSettings.StreamFormat ? formSettings.StreamFormat : formSettings.PaymentOptionSettings.StreamMessageFormat;
  zoomSdk.onMessage((event) => {
    const newStrm1 = JSON.parse(event.payload);
    let StreamMarquee = newStrm1.data;
    if(!Array.isArray(StreamMarquee)) StreamMarquee = JSON.parse(StreamMarquee);
  //   const data =
  //     StreamMarquee &&
  //     StreamMarquee.length &&
  //     StreamMarquee.map((stream) => {
  //       const mapObj = {
  //         "{Patron Display Name}" : stream.firstName+' '+stream.lastName,
  //         "{Amount}": stream.amount,
  //         "{Date}" : stream.dDate
  //       }
  //       const Lstring = formSettings.PaymentOptionSettings.StreamMessageFormat.replace("{Comments}","");
  //       const Lstream = replaceAll(Lstring, mapObj);
  //       return(
  //       <span className="spacing">
  //         {" " +Lstream + `  `}
  //       </span>
  //       );
  // });
    let totalAmount = StreamMarquee && StreamMarquee.length && StreamMarquee.reduce(
      (n, { amount }) => parseFloat(n) + parseFloat(amount),
      0
    );

    setStreamData(StreamMarquee);
    setTotalAmount(totalAmount);
  });

  useLayoutEffect(() => {
    document.body.style.backgroundColor = "transparent";
  });

 // useEffect(() => {
  //   const data =
  //     donationStream &&
  //     donationStream.length &&
  //     donationStream.map((stream) => {
  //       const mapObj = {
  //         "{Patron Display Name}" : stream.firstName+' '+stream.lastName,
  //         "{Amount}": stream.amount,
  //         "{Date}" : stream.dDate
  //       }
  //       const Lstring = formSettings.PaymentOptionSettings.StreamMessageFormat.replace("{Comments}","");
  //       const Lstream = replaceAll(Lstring, mapObj);
  //       return(
  //       <span className="spacing">
  //         {" " + Lstream + `  `}
  //       </span>);
  // });
    // let totalAmount = donationStream && donationStream.length && donationStream.reduce(
    //   (n, { amount }) => parseFloat(n) + parseFloat(amount),
    //   0
    // );
    // setStreamData(donationStream);
    // setTotalAmount(totalAmount);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  //}, [donationStream]);

  //let data = streamData && streamData.length && streamData.map(stream => ' '+stream.firstName +` has just donated `+ stream.amount+' ');

  return (
    <>
      <div
        className="stream-logo"
        style={{ margin: "0.5rem 0 0 0", position: "absolute" }}
      >
        <img
          src={`newimgs/givent_logo.png?${(new Date()).getTime()}`}
          style={{ width: "120px" }}
          alt="I donated"
        />{" "}
      </div>
      <div className="donatio-stream-marquee">
        
        {/* eslint-disable-next-line jsx-a11y/no-distracting-elements */}
        <marquee direction="right" heigt="100%">
          {/* {streamData ? <p className="donation-text">{streamData}</p> : null} */}
          {streamData && streamData.length ? streamData.map((stream)=>{
            let mapObj = {}, streamFormat='';
            if(formSettings.StreamFormat){
              streamFormat = formSettings.StreamFormat;
               mapObj = {
                "{Patron First_Initial}": stream.firstName.charAt(0),
                "{Patron First_Name}" : stream.firstName+' ',
                "{Patron Last_Initial}": stream.lastName.charAt(0),
                "{Patron Last_Name}" : stream.lastName,
                "{Amount}": formSettings.Currency+stream.amount
              }
            }else{
              streamFormat = formSettings.PaymentOptionSettings.StreamMessageFormat;
                mapObj = {
                  "{Patron Display Name}" : stream.firstName+' '+stream.lastName,
                  "{Donor Display Name}" : stream.firstName+' '+stream.lastName,
                  "{Amount}": formSettings.Currency+stream.amount,
                  "{Date}" : stream.dDate
                }
            }
        
       
        const Lstring = streamFormat.replace("{Comments}","");
        const Lstream = replaceAll(Lstring, mapObj);
        return(
        <span className="spacing donation-text">
          {" " +Lstream + `  `}
        </span>
        );
        }): (
          <span className="spacing donation-text">Loading... Please wait!</span>
        )}
        </marquee>

        <div className="donation-remaining">
          &nbsp;
          {totalamount
            ? formSettings.Currency +
              parseFloat(targetAmount - totalamount).toFixed(2) +
              ` remaining to reach our ${formSettings.Currency} ${targetAmount.toFixed(2)} goal`
            : null}{" "}
        </div>
      </div>
    </>
  );
}

export default LiveStream;
