import React, { useEffect, useState } from "react";
import zoomSdk from "@zoom/appssdk";
import axios from "axios";
import { AUTH_API, SALT_KEY, ZOOM_USER_API } from "../utility/constants";
import { useDispatch } from "react-redux";
import { bindZoomData, bindZoomIsInstalled } from "../store/donationSlice";
import CryptoJS from "crypto-js";

export const Authorization = (props) => {
  const {
    handleError,
    handleUser,
    handleUserContextStatus,
    user,
    userContextStatus,
    meetingUUID,
    userinfo,
    setUserAccountInfo,
  } = props;

  const [userAuthorized, setUserAuthorized] = useState(null);
  const [showInClientOAuthPrompt, setShowInClientOAuthPrompt] = useState(false);
  const [inGuestMode, setInGuestMode] = useState(false);

  const dispatch = useDispatch();

  const promptAuthorize = async () => {
    try {
      const promptAuthResponse = await zoomSdk.promptAuthorize();
      console.log(promptAuthResponse);
    } catch (e) {
      console.error(e);
    }
  };

  const authorize = async () => {
    setShowInClientOAuthPrompt(false);

    let authorizeResponse;
    try {
      authorizeResponse = await axios.post(`${AUTH_API}/zoomAPIs/install`, {
        httpMethod: "POST",
        path: "/install",
        body: {},
      });
      if (authorizeResponse.data.body.url) {
        // window.location.href = authorizeResponse.data.body.url;
        localStorage.setItem(
          "verifier",
          JSON.stringify(authorizeResponse.data.body.verifier)
        );
      } else {
        setShowInClientOAuthPrompt(true);
        return;
      }
      //   authorizeResponse = await (await fetch("/api/zoomapp/authorize")).json();
      //   console.log(authorizeResponse);
      //   if (!authorizeResponse || !authorizeResponse.codeChallenge) {

      //     setShowInClientOAuthPrompt(true);
      //     return;
      //   }
    } catch (e) {
      console.error(e);
    }
    console.log("authRes===", authorizeResponse);
    const { codeChallenge, state } = authorizeResponse;

    const authorizeOptions = {
      codeChallenge: codeChallenge,
      state: state,
    };

    try {
      const zoomAuthResponse = await zoomSdk.authorize(authorizeOptions);
      //   if (authorizeResponse.data.body.url) {
      //     window.location.href = authorizeResponse.data.body.url;
      //   }
      console.log("Auuuu==", zoomAuthResponse);
    } catch (e) {
      console.error(e);
    }
  };

  /** Redirect to home page after authorized */
  useEffect(() => {
    zoomSdk.addEventListener("onAuthorized", (event) => {
      const { code, state } = event;
      console.log("code==", code, "state===", state);
      const bodyData = {
        code: code,
      };
      axios
        .post(
          `${AUTH_API}/zoomAPIs/auth`,
          { httpMethod: "POST", path: "/auth", body: bodyData },
          { headers: { "Content-Type": "application/json" } }
        )
        .then((res) => {
          dispatch(bindZoomIsInstalled(true));
          localStorage.setItem("iszoomappinstalled", true);
          setUserAuthorized(true);
          handleError(null);
          window.location.href = "/";
        });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleError]);

  /** To fetch User If Authorized */
  useEffect(() => {
    zoomSdk.addEventListener("onMyUserContextChange", (event) => {
      handleUserContextStatus(event.status);
      console.log("event===", event);
    });
    async function fetchUser() {
      try {
        if (meetingUUID && userinfo) {
          let postData = {
            tableName: meetingUUID.meetingUUID + "-participants",
            userId: userinfo.participantId,
          };

          let postBody = CryptoJS.AES.encrypt(
            JSON.stringify({ body: { postData } }),
            SALT_KEY
          ).toString();
          axios
            .post(`${ZOOM_USER_API}/zoomdata/getuser`, {
              httpMethod: "POST",
              path: "/",
              postBody,
            })
            .then((res) => {
              if (
                res.data &&
                res.data.statusCode === 200 &&
                res.data.body.Items[0]
              ) {
                const zoomuData = res.data.body.Items[0].Attributes;
                const display_name = zoomuData[4].Value;
                let first_name = "",
                  last_name = "";
                if (display_name.indexOf(" ") >= 0) {
                  first_name = display_name.split(" ")[0];
                  last_name = display_name.split(" ")[1];
                }
                const zoomuserinfo = {
                  email: zoomuData[0].Value,
                  display_name,
                  first_name,
                  last_name,
                  userId: zoomuData[5].Value,
                };

                setUserAccountInfo(zoomuserinfo);

                dispatch(bindZoomData(zoomuserinfo));
                setShowInClientOAuthPrompt(false);
              }
            })
            .catch((err) => console.log("eeee===", err));
        }

        // An example of using the Zoom REST API via proxy
        // const response = await fetch("/zoom/api/v2/users/me");
        // if (response.status !== 200) throw new Error();
        // const user = await response.json();
        // handleUser(user);
        // setShowInClientOAuthPrompt(false);
      } catch (error) {
        setShowInClientOAuthPrompt(true);
      }
    }

    if (userContextStatus === "authorized") {
      setInGuestMode(false);
      fetchUser();
    } else if (
      userContextStatus === "unauthenticated" ||
      userContextStatus === "authenticated"
    ) {
      setInGuestMode(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleUser, handleUserContextStatus, userAuthorized, userContextStatus]);
  console.log("ustatus===", userContextStatus);
  return (
    <>
      <link
        rel="stylesheet"
        type="text/css"
        href="https://aws.assets.clickandpledge.com/zoomapp/css/custom.css"
      />
      <main>
        <div
          id="CnpZoomSnippetsMain"
          className="container cnp_zoom_form_body"
          style={{width:'90%',marginTop:'30%'}}
        >
          <section className="row cnp_zoom_login_section">
            <div className="col-12">
              <p>Please click below button to add our app.</p>
            </div>
            {!inGuestMode && (
              <button
                variant="primary"
                className="cnp_zoom_publish_btn"
                onClick={inGuestMode ? promptAuthorize : authorize}
               
              >
                {inGuestMode ? "promptAuthorize" : "Authorize"}
              </button>
            )}
          </section>
        </div>
      </main>
    </>
  );
};
