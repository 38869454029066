import React from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { AUTH_API } from "../utility/constants";



const Install = () => {


  const isInstalled = useSelector((state) => state?.donation?.zoomIsInstalled || false);
  const isAppInstalled = localStorage.getItem('iszoomappinstalled');
  


  // const installapp = async () => {

  //   const authres = await axios.post(`${AUTH_API}/install`, { httpMethod: "POST", path: "/install", body: {} });
  
  //   if (authres.data.body.url) {
  //     window.location.href = authres.data.body.url;
  //     localStorage.setItem("verifier", JSON.stringify(authres.data.body.verifier));
  //   }
  // }

  const handleGiventTabOpen = () => {
    window.open('https://clickandpledge.com/givent', '_blank');
  };
 

  return (
    <>
    {/* <link rel='stylesheet' type='text/css' href='../../css/custom.css'  /> */}
   
 
     <div className="recurring_transaction_container">
        <div className="recurring_transaction_wrapper">
            <div className="recurring_transaction_card mt_2" id="recurring_main_tabs">
                <div className="recurring_transaction_card_body">
                  <div className="recurring_transaction_main_form" >
                      <div className="recurring_transaction_tab_content active">
                      <div className="transaction_info_logo_container">
                                <img src="newimgs/givent_logo.png" alt="Click and Pledge" style={{width:'150px'}}/>
                        </div>
                        <div className="transaction_info_wrapper">
                        <div className="form_input_wrapper mt_1">
                        <div className="recurring_transaction_divider">
                          <span>Turn your next "ZOOMINAR" into a Fundraiser</span>
                        </div>
                      
                        </div>
                        {(isInstalled || isAppInstalled) ? (
                        
                        <div className="form_input_wrapper mt_1">
                        <label for="">Thank you for adding our App!</label>
                        {/* <label for="">Now you can see the Givent<sup>TM</sup> in the zoom app under apps tab.</label> */}
                        <label for="">
                            <div className="dw-col mt_1">
                                <button className="full_width full_button" type="button" data-index="0" onClick={handleGiventTabOpen}>
                                Learn More
                                </button>
                            </div> </label>
                        </div>
                        
                          ):(
                            
                            <div className="form_input_wrapper mt_2">
                            {/* <label for="">Turn your next "ZOOMINAR" into a Fundraiser</label> */}
                           
                            <label for="">
                            <div className="dw-col mt_1" style={{display:'flex', justifyContent:'center'}}>
                                <button className="full_width full_button" type="button" data-index="0" style={{width:'50%'}} onClick={handleGiventTabOpen}>
                                 Learn More
                                </button>
                            </div> </label>
                            </div>
                          )}
                        </div>
                        </div>
                      </div>
                      
                  </div>
                </div>

                </div> 
            </div> 
               
   

    </>
  );
};

export default Install;