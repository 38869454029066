import React, { useEffect } from "react";
import axios from "axios";

import { AUTH_API } from "../utility/constants";


const Auth = () => {


useEffect(()=>{
    
    axios.post(`${AUTH_API}/install`, { httpMethod: "POST", path: "/install", body: {} })
                        .then((authres)=>{
                          if (authres.data.body.url) {
                            window.location.href = authres.data.body.url;
                            localStorage.setItem("verifier", JSON.stringify(authres.data.body.verifier));
                          }
                        });
  
     
    
// eslint-disable-next-line react-hooks/exhaustive-deps
},[])



  return (
    <>
   
    </>
  );
};

export default Auth;