import React, { useState } from "react";

function AlertMsg(props) {
 
    const [showMessage, setShowMessage] = useState(true)
    //subMessage
    if (props.message && props.isClose) {
        setTimeout(() => {
            setShowMessage(false);
            if(props.callfunc) props.callfunc();
        }, [8000]);
      }

  return (
    <div className={`alert-container ${showMessage ? 'show':'hide'}`}>
        {props.type === "success" && (
            <div className="recurring_successful_transaction_wrapper">
              <div className="succesful_transaction_wrapper" >
                 {props.message && <p className="title">{props.message}</p>}
                 {props.subMessage && <p>{props.subMessage}</p>}
              </div>
            {/* <img src="./newimgs/success-icon.svg" alt="success" /> */}
            
          </div>
        )}
        {props.type === "failure" && (
        <div className="recurring_error_transaction_wrapper">
          <div className="error_transaction_wrapper">
              {props.message && <p className="title">{props.message}</p>}
              {props.subMessage && <p className="title">{props.subMessage}</p>}
            </div>
           {/* <img src="./newimgs/error-icon.svg" alt="success" /> */}
           
        </div>
        )}
    </div>
  );
}

export default AlertMsg;