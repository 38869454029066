import React, { useState } from "react";
import zoomSdk from "@zoom/appssdk";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Spinner } from "react-bootstrap";
import CryptoJS from "crypto-js";
import { SALT_KEY, ZOOM_SDB_API } from "../utility/constants";
import AlertMsg from "./Alert";

const PublishForm = (props) => {
  const [formpublished, setFormPublished] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [invitationerror, setInvitationError] = useState(false);
  const recordindex = useSelector(
    (state) => state?.donation?.useraccountinfo || {}
  );
  // const forminfo = useSelector(
  //   (state) => state?.donation?.donationFormDetails || {}
  // );
  const formSettings = useSelector(
    (state) => state?.donation?.formSettings || {}
  );
  const navigate = useNavigate();

  const publish = async (e) => {
    e.preventDefault();
    setShowLoader(true);

    // let publishData = {
    //   eId: props.meetingData.meetingID,
    //   zoomaccId: props.userData.account_id,
    //   Id: recordindex.recordId,
    // };
    let publishData = {
      eId: props.meetingData.meetingUUID,
      Id: recordindex.recordId,
    };
    let postBody = CryptoJS.AES.encrypt(
      JSON.stringify({ body: { publishData } }),
      SALT_KEY
    ).toString();
    axios
      .post(`${ZOOM_SDB_API}/publishform`, {
        httpMethod: "POST",
        path: "/publishform",
        postBody,
      })
      .then(async (res) => {
        if (res.data.body) {
           await zoomSdk.sendAppInvitationToAllParticipants();
          
          setFormPublished(true);
          //setTimeout(() => navigate("/loadform"), 3000);
          navigate("/loadform",{ state: { showDForm: true } });
          setShowLoader(false);
        }
      })
      .catch((err) => {
        console.log( err)
        if(err.toString().indexOf('denied') > -1){
          setFormPublished(true);
          navigate("/loadform", { state: { showDForm: true } });
          setShowLoader(false);
        }else{
          setFormPublished(true);
          setInvitationError(true);
          setShowLoader(false);
        }
       
        
        console.log(err)
      });
  };

  const reLoad = () => {
    navigate('/loadform')
  }
 
  return (
    <>
      <div className="recurring_transaction_container">
        <div className="recurring_transaction_wrapper">
          <div className="recurring_transaction_card mt_2">
            <div className="recurring_transaction_card_header">
              <div className="transaction_info_logo_container">
              {/* {!formSettings.FormLogo ? (
                        <img src="newimgs/cp_logo.png" alt="Click and Pledge" />
                      ) : formSettings.FormLogo && formSettings.FormLogo.startsWith('https') ? (
                        <img src={formSettings.FormLogo} alt="Logo" className="logo_img"/>
                      ): (
                        <img src={`https://connect.clickandpledge.com/w${formSettings.FormLogo}`} alt="Logo" className="logo_img" />
                      )} */}
                {/* <img src="newimgs/cp_logo.png" alt="Click and Pledge" /> */}
                {!formSettings.FormHeader ? (
                        <img src={`newimgs/givent_logo.png?${(new Date()).getTime()}`} alt="Click and Pledge" />
                      ):(
                        <div className="header-name">{formSettings.FormHeader}</div>
                      )}
              </div>

              {formpublished && !invitationerror && (
                <AlertMsg
                  message="Form Published Successfully! Please wait your form is loading..."
                  type="success"
                  isClose={true}
                />
              )}
              {invitationerror && (
                <>
                 <AlertMsg
                 message="Send Invitation timeout! Please reload your app again."
                 type="failure"
                 isClose={true}
               />
               <p onClick={reLoad} style={{color:'blue', cursor:'pointer'}}>Reload</p>
               </>
              )}
              {showLoader && (
                <div className="loader-container">
                 <div className="lds-ellipsis">
                 <div></div>
                 <div></div>
                 <div></div>
                 <div></div>
               </div>
               </div>
              )}
              {/* <div className="recurring_transaction_card_title_wrapper">
                <h1 className="">{forminfo.campaignName}</h1>
              </div> */}
            </div>
            <div className="recurring_transaction_card_body">
              {/* <p>
              <span className="section_title">{forminfo.formName}</span> - This is your donation form, please publish
                it to raise donations!.
              </p> */}

              <div className="form_input_wrapper d-flex justify-content-between mt_1">
                <button
                  className="full_button tabindex_btn next"
                  type="button"
                  data-index="0"
                  onClick={publish}
                  disabled={formpublished}
                >
                  {!showLoader ? (
                    <>
                      <span className="">Publish & Broadcast</span>
                    </>
                  ) : (
                    <>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      <span className="">Loading...</span>{" "}
                    </>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PublishForm;
