import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';


const initialState = {
    zoomData: [],
    countriesStates: [], 
    zoomIsInstalled: false,
    useraccountinfo: {},
    lastStreamTime: {},
    donationFormDetails: {},
    donationStream: [],
    totalDonationAmount: {},
    recurringSection: {},
    amountSection: {},
    convenienceFeeSection:[],
    formGUID: '',
    zoomFormData:[],
    formSettings:{},
    paymentSection: [],
    convenienceFeeAmount:'',
    donationAmountSelected:'',
    installementSelected:'',
    recurringSelection:'',
    hasInstallmentError: false,
    haslessInstallmentAmt: false,
    finalDonationAmt:'',
    convenienceFeeSelected: false,
    validationSection: { 'AmountSection': false, 'PaymentSection': false },
    installmentCharge: '',
    installmentErrorMsg:'',
    multiDonationErr:'',
    multiDonationVals:{},
    showAsAnonymous: false,
    isDonationDone:false,
    isPaySecTouched: false,
    isDonationAmountEmpty: false,
    isIndefiniteRecurring: false,
    recurringMethod:'',
    paymentTypeMethod:'',
    isEventExpired:'',
    paymentOrderNo:'',
    paymentOrderItem:{},
    conFeeOrderItem:{},
    donationOrderItems:{},
    multiDonationOrderItems:[],
    multiPayTotalTaxDeduct:0,
    defaultDonationAmount:0,
    paymentErr:false,
    touchedfields:[],
    showhideForm:true,
    isInstallmentTouched: false,
    otherAmountMinMaxError:false,
    finalDonationAmountError:false,
    noofperiodicity:'',
    showoneTimePayOption:false,
    noRecurringOptionAvailable:false,
    giventID:0
}



export const retrieveCountriesStates = createAsyncThunk(
    "donation/countrystates",
    async () => {
        const res = await axios.get(`https://km30v7d1la.execute-api.us-east-1.amazonaws.com/getcountriesstates`);
        return JSON.parse(res.data.body);
    }
);

export const donationSlice = createSlice({
    name: 'donation',
    initialState,
    reducers: {
        bindZoomData: (state, action) => {
            state.zoomData = action.payload
        },
       
        bindZoomIsInstalled: (state, action) => {
            state.zoomIsInstalled = action.payload
        },
      
        bindUserAccountData: (state, action) => {
            state.useraccountinfo = action.payload
        },
        bindLastStreamTime: (state, action) => {
            state.lastStreamTime = action.payload
        },
        bindDonationFormDetails: (state, action) => {
            state.donationFormDetails = action.payload
        },
        bindDonationStream: (state, action) => {
            state.donationStream = action.payload
        },
        bindTotalDonationAmount: (state, action) => {
            state.totalDonationAmount = action.payload
        },
       
        changeFormData: (state, action) => {
            //return [...state, action.payload.formdata]
            state.formData = action.payload.formdata
        },

        /**new form changes */
        bindRecurringSection: (state, action) => {
            state.recurringSection = action.payload
        },
        bindAmountSection: (state, action) => {
            state.amountSection = action.payload
        },
        bindPaymentSection: (state, action) => {
            state.paymentSection = action.payload
        },
        bindConvenienceFeeSection: (state, action) =>{
            state.convenienceFeeSection = action.payload
        },
      
        bindFormGUID: (state, action) => {
            state.formGUID = action.payload
        },
        bindZoomFormData: (state, action) => {
            state.zoomFormData = action.payload
        },
        bindZoomFormSettings: (state, action) => {
            state.formSettings = action.payload
        },
        bindConvenienceFeeAmount: (state, action) =>{
            state.convenienceFeeAmount = action.payload
        },
        bindConvenienceFeeSelected: (state, action) =>{
            state.convenienceFeeSelected = action.payload
        },
        bindDonationAmountSelected: (state, action) =>{
            state.donationAmountSelected = action.payload
        },
        bindInstallements: (state, action) =>{
            state.installementSelected = action.payload
        },
        bindRecurringSelection: (state, action) =>{
            state.recurringSelection = action.payload
        },
        bindRecurringMethod: (state, action) =>{
            state.recurringMethod = action.payload
        },
        bindPaymentTypeMethod: (state, action) =>{
            state.paymentTypeMethod = action.payload
        },
        bindInstallmentError: (state, action) =>{
            state.hasInstallmentError = action.payload
        },
        bindHaslessInstallmentAmt: (state, action) =>{
            state.haslessInstallmentAmt = action.payload
        },
        
        bindFinalDonationAmount: (state, action) =>{
            state.finalDonationAmt = action.payload
        },
        bindValidationSection: (state, action) => {
            state.validationSection = action.payload
        },
        bindInstallmentChargeValue: (state, action) => {
            state.installmentCharge = action.payload
        },
        bindInstallmentErrorMsg: (state, action) => {
            state.installmentErrorMsg = action.payload
        },
        bindMultiDonationError: (state, action) => {
            state.multiDonationErr = action.payload
        },
        bindMultiDonationVals: (state,action) =>{
            state.multiDonationVals = action.payload
        },
        bindShowAsAnonymous: (state,action) =>{
            state.showAsAnonymous = action.payload
        },
        bindIsDonationDone: (state, action) => {
            state.isDonationDone = action.payload
        },
        bindPaySecTouched: (state, action) => {
            state.isPaySecTouched = action.payload
        },
        bindIsDonationAmountEmpty : (state, action) =>{
            state.isDonationAmountEmpty = action.payload
        },
        bindIsInDefiniteRecurring : (state, action) =>{
            state.isIndefiniteRecurring = action.payload
        },
        bindIsEventExpired : (state, action) =>{
            state.isEventExpired = action.payload
        },
        bindPaymentOrderNo : (state, action) =>{
            state.paymentOrderNo = action.payload
        },
        bindPaymentOrderItem: (state, action) =>{
            state.paymentOrderItem = action.payload
        },
        bindConFeeOrderItem: (state, action) =>{
            state.conFeeOrderItem = action.payload
        },
        bindDonationOrderItems: (state, action) =>{
            state.donationOrderItems = action.payload
        },
        bindMultiDonationOrderItems: (state, action) =>{
            state.multiDonationOrderItems = action.payload
        },
        bindMultiPayTotalTaxDeduct: (state, action) =>{
            state.multiPayTotalTaxDeduct = action.payload
        },
        bindDefaultDonationAmount: (state, action) =>{
            state.defaultDonationAmount = action.payload
        },
        bindPaymentError: (state, action) =>{
            state.paymentErr = action.payload
        },
        bindTouchedFields: (state, action) =>{
            state.touchedfields = action.payload
        },
        bindShowHideForm: (state, action) =>{
            state.showhideForm = action.payload
        },
        bindIsInstallmentTouched: (state, action) =>{
            state.isInstallmentTouched = action.payload
        },
        bindOtherAmountMinMaxError: (state, action) =>{
            state.otherAmountMinMaxError = action.payload
        },
        bindFinalDonationAmountError: (state, action) =>{
            state.finalDonationAmountError = action.payload
        },
        bindNoOfPeriodicity: (state, action) =>{
            state.noofperiodicity = action.payload
        },
        bindShowOneTimePayOption: (state, action) =>{
            state.showoneTimePayOption = action.payload
        },
        bindNoRecurringOptionAvailable: (state, action) =>{
            state.noRecurringOptionAvailable = action.payload
        },
        bindGiventID: (state, action) => {
            state.giventID = action.payload
        },
        reset: (state,action) => {
            console.log("reset===",action.payload)

            state.zoomFormData = action.payload
           
        }
       
    },
    extraReducers: {
        [retrieveCountriesStates.fulfilled]: (state, action) => {
            state.countriesStates = action.payload;
        }
    }
})

// Action creators are generated for each case reducer function
export const {  
    bindZoomData, 
    bindZoomIsInstalled, 
    changeFormData, 
    bindUserAccountData, 
    bindDonationFormDetails, 
    bindDonationStream, 
    bindTotalDonationAmount,  
    bindLastStreamTime,
    bindRecurringSection,
    bindAmountSection,
    bindConvenienceFeeSection,
    bindFormGUID,
    bindZoomFormData,
    bindZoomFormSettings,
    bindConvenienceFeeAmount,
    bindPaymentSection,
    bindConvenienceFeeSelected,
    bindDonationAmountSelected,
    bindInstallements,
    bindRecurringSelection,
    bindRecurringMethod,
    bindPaymentTypeMethod,
    bindInstallmentError,
    bindHaslessInstallmentAmt,
    bindFinalDonationAmount,
    bindValidationSection,
    bindInstallmentChargeValue,
    bindInstallmentErrorMsg,
    bindMultiDonationError,
    bindMultiDonationVals,
    bindShowAsAnonymous,
    bindIsDonationDone,
    bindPaySecTouched,
    bindIsDonationAmountEmpty,
    bindIsInDefiniteRecurring,
    bindIsEventExpired,
    bindPaymentOrderNo,
    bindPaymentOrderItem,
    bindConFeeOrderItem,
    bindDonationOrderItems,
    bindMultiDonationOrderItems,
    bindMultiPayTotalTaxDeduct,
    bindDefaultDonationAmount,
    bindPaymentError,
    bindTouchedFields,
    bindShowHideForm,
    bindIsInstallmentTouched,
    bindOtherAmountMinMaxError,
    bindFinalDonationAmountError,
    bindNoOfPeriodicity,
    bindShowOneTimePayOption,
    bindNoRecurringOptionAvailable,
    bindGiventID,
    reset
} = donationSlice.actions

export default donationSlice.reducer